import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { createContext, useCallback, useEffect, useState } from "react";
import games from "../data/games/games";

export const GameContext = createContext();

const { Provider } = GameContext;

export const GameProvider = ({ children }) => {
  const router = useRouter();
  const [gameByPopularity, setGameByPopularity] = useState(undefined);
  const [gamesToShow, setGamesToShow] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const query = router.query.search;
    if (query) setSearchQuery(query);
    else setSearchQuery("");
  }, [router.query.search]);

  const scrollToContent = useCallback(() => {
    var el = document.getElementById("home-cards");
    if (!el) setTimeout(() => scrollToContent(), 1000);
    else window.scroll({ top: el.offsetTop, behavior: "smooth" });
  }, []);

  // check if there is a query parameter and filter the games with it
  useEffect(() => {
    if (searchQuery && gameByPopularity !== undefined) {
      scrollToContent();
      const gamesFiltered = gameByPopularity.filter((game) => {
        return t(`${game.game}.name`).toLowerCase().includes(searchQuery.toLowerCase());
      });

      setGamesToShow(gamesFiltered);
    }
  }, [gameByPopularity, searchQuery, scrollToContent, t]);

  const handleFilterByCount = useCallback(async (gameByCount) => {
    const gameByPop = [];

    gameByCount.forEach((item) => {
      games.forEach((game) => item.type === game.id && gameByPop.push(game));
    });

    games.forEach((elem) => !gameByPop.includes(elem) && gameByPop.push(elem));

    setGameByPopularity(gameByPop);
    setGamesToShow(gameByPop);
  }, []);

  // When user click to "See all games"
  const handleClickBtn = () => {
    router.push("/" + router.locale, undefined, { shallow: true });
    setGamesToShow(gameByPopularity);
  };

  const value = {
    handleFilterByCount,
    searchQuery,
    gamesToShow,
    setGamesToShow,
    setGameByPopularity,
    setSearchQuery,
    searchQuery,
    setSearchQuery,
    gameByPopularity,
    scrollToContent,
    handleClickBtn,
  };

  return <Provider value={value}>{children}</Provider>;
};
