import { useEffect } from "react";
import { appWithTranslation } from "next-i18next";
import TagManager from "react-gtm-module";
import "../styles/globals.scss";
import config from "../config";
import { GameProvider } from "../context/GameContext";
import nextI18NextConfig from "../../next-i18next.config";

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    TagManager.initialize({ gtmId: config.gtmId });
  }, []);

  return (
    <GameProvider>
      <Component {...pageProps} />
    </GameProvider>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
