const config = {
  gtmId: "GTM-5ZN46KR",
  environment: "development",
  message: "https://server-message-staging.sbfs.io",
};

if (typeof window !== "undefined") {
  if (window.location.hostname === "staging.friend-code.com") {
    config.environment = "staging";
    config.message = "https://server-message-staging.sbfs.io";
  }
}

if (typeof window !== "undefined") {
  if (window.location.hostname === "friend-code.com") {
    config.gtmId = "GTM-5FPMQJT";
    config.environment = "production";
    config.message = "https://server-message.sbfs.io";
  }
}

export default config;
